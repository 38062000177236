import { ThemeProvider, createTheme } from "@mui/material";
import ReactDOM from "react-dom/client";
import { ErrorBoundary } from "react-error-boundary";
import {
  createBrowserRouter,
  createHashRouter,
  RouterProvider,
} from "react-router-dom";

import { EveWorldProvider } from "@eveworld/contexts";
import { ErrorNotice, ErrorNoticeTypes } from "@eveworld/ui-components";

import EntityView from "./components/EntityView";
import { Map } from "./components/Map";

import App from "./App";
import FourOhFourView from "./components/FourOhFourView";
// import AgreementView from "./components/AgreementView";

const darkTheme = createTheme({
  palette: {
    mode: "dark",
  },

  components: {
    MuiPaper: {
      styleOverrides: {
        root: {
          padding: "0px !important",
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        root: {
          letterSpacing: 0,
        },
        message: {
          padding: "8px 16px !important",
        },
      },
    },
  },
});

const router = createHashRouter([
  {
    element: <App />,
    children: [
      {
        path: "/map",
        element: <Map />,
      },
      {
        path: "/",
        element: <EntityView />,
      },
      {
        path: "/dex/*",
        element: <EntityView />,
      },
      // {
      //   path: "/agreement",
      //   element: <AgreementView />,
      // },
      {
        path: "*",
        element: <FourOhFourView />,
      },
    ],
  },
]);

ReactDOM.createRoot(document.getElementById("root")!).render(
  <ErrorBoundary
    fallback={
      <ErrorNotice
        type={ErrorNoticeTypes.MESSAGE}
        errorMessage="Anomaly detected: See developer console for details"
      />
    }
  >
    <EveWorldProvider>
      <ThemeProvider theme={darkTheme}>
        <RouterProvider router={router} />
      </ThemeProvider>
    </EveWorldProvider>
    ,
  </ErrorBoundary>
);
