import { LIGHT_YEAR_IN_METERS } from "@/constants";
import { MapDisplayType } from "./reducer";

const DEFAULT_MAP_SETTINGS = {
  nodeRadius : 0.8,
}

export enum PathingParams {
  StartNode = "startNode",
  DestinationNode = "destinationNode",
  FocusedNode = "focusedNode",
}

export enum MeshInstanceId {
  Nodes = 1,
  Links = 2,
  BoundingSphere = 3,
}

export const SETTINGS = {
  labelPosition: [2, 2, 1] as [number, number, number],
  labelSize: 1,
  nodeRadius: DEFAULT_MAP_SETTINGS.nodeRadius,
  linkWidth: 0.1,
  highlightedNodeRadius: 5,
  defaultSelectedNodeRadiusInMeters: 500 * LIGHT_YEAR_IN_METERS,
  scale: [1, -1, -1] as [number, number, number],
};

export const COLOR_THEME = {
  baseSystemColor: 0xadd8e6,
  baseSystemOpacity: 0.7,

  selectedSystemColor: 0x94e5ff,
  selectedSystemOpacity: 1,

  selectedSystemHaloColor: 0xadd8e6,
  selectedSystemHaloOpacity: 0.15,

  selectedDestinationSystemColor: 0xe7071b,
  selectedDestinationSystemOpacity: 0.7,

  selectedDestinationSystemHaloColor: 0xee515f,
  selectedDestinationSystemHaloOpacity: 0.1,

  selectedConstellationColor: 0xd9a759,
  selectedConstellationOpacity: 1,

  npcStationColor: 0xf7a768,
  tertiaryStationColor: 0x61ff00,
  smartGateHighlighterColor: 0xffaa00,

  linkColor: 0x808080,
  linkOpacity: 0.5,

  pathColor: 0x00ffff,
};

export const mapDisplayTypeKey: Record<MapDisplayType, string> = {
  [MapDisplayType.NONE]: "solarSystemId",
  [MapDisplayType.REGION]: "regionID",
  [MapDisplayType.NPC_STATION]: "solarSystemId",
  [MapDisplayType.ACTIVE_ASSEMBLIES_COUNT]: "solarSystemId",
  [MapDisplayType.SMART_GATES_COUNT]: "solarSystemId",
  [MapDisplayType.RIFTS]: "solarSystemId",
};

export const mapFiltersEnabled: Record<MapDisplayType, boolean> = {
  [MapDisplayType.NONE]: true,
  [MapDisplayType.REGION]: true,
  [MapDisplayType.NPC_STATION]: true,
  [MapDisplayType.ACTIVE_ASSEMBLIES_COUNT]: true,
  [MapDisplayType.SMART_GATES_COUNT]: true,
  [MapDisplayType.RIFTS]: false,
};

export const mapDisplayTypeLabel: Record<MapDisplayType, string> = {
  [MapDisplayType.NONE]: "None",
  [MapDisplayType.REGION]: "Regions",
  [MapDisplayType.NPC_STATION]: "NPC Stations",
  [MapDisplayType.ACTIVE_ASSEMBLIES_COUNT]: "Active Assemblies",
  [MapDisplayType.SMART_GATES_COUNT]: "Active Smart Gates",
  [MapDisplayType.RIFTS]: "Historical Rift Spawns",
};

export const mapDisplayTypeAsync: Record<MapDisplayType, boolean> = {
  [MapDisplayType.NONE]: false,
  [MapDisplayType.REGION]: false,
  [MapDisplayType.NPC_STATION]: false,
  [MapDisplayType.RIFTS]: false,
  [MapDisplayType.ACTIVE_ASSEMBLIES_COUNT]: true,
  [MapDisplayType.SMART_GATES_COUNT]: true,
};