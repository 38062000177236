import BaseSSU from "./BaseSSU";
import AdminPanel from "./AdminPanel";

export const deriveEntityView = (
  a: "SmartStorageUnit" | "SmartGate" | "SmartTurret"
): { admin: any; user: any } => {
  switch (a) {
    // case "SmartStorageUnit":
    //   return { admin: TradingAdminPanel, user: DexSSU };
    default:
      return { admin: AdminPanel, user: BaseSSU };
  }
};
