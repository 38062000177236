import { useState, useEffect, useRef, useReducer } from "react";
import { default as ForceGraph3D } from "3d-force-graph";
import { EveInput } from "@eveworld/ui-components";
import * as THREE from "three";
import { FontLoader } from "three/examples/jsm/loaders/FontLoader";
import { TTFLoader } from "three/examples/jsm/loaders/TTFLoader";
import { UnrealBloomPass } from "three/examples/jsm/postprocessing/UnrealBloomPass.js";

import "./canvas.css";

import {
  buildInstanceMap,
  deriveCameraPosition,
  deriveColorsAndRadii,
  deriveColorsAndOverlayOfLink,
  deriveFocusPosition,
  deriveStartPosition,
  getCoords,
  getScaleFactor,
  LinkItem,
  buildLinkInstanceMap,
} from "./utils";
import {
  createNavLocation,
  lightYearsBetweenTwo3DPoints,
  useEffectOnce,
} from "@/utils";
import {
  initialMapReducerState,
  MapActions,
  MapDisplayType,
  mapReducer,
} from "./reducer";
import { updateNode } from "./threehandlers";
import {
  COLOR_THEME,
  mapDisplayTypeAsync,
  mapDisplayTypeLabel,
  mapFiltersEnabled,
  MeshInstanceId,
  PathingParams,
  SETTINGS,
} from "./constants";
import { buildPathTree } from "./pathing";
import { useLocation, useNavigate } from "react-router-dom";
import { useSmartObject } from "@eveworld/contexts";
import { useMUD } from "@/MUDContext";

const GalaxyMap = () => {
  const [state, dispatch] = useReducer(mapReducer, initialMapReducerState);
  const location = useLocation();
  const navigate = useNavigate();
  const graphRef = useRef<null | any>(null);
  const containerRef = useRef<HTMLDivElement | null>(null);
  const [nodeMesh, setNodeMesh] = useState<THREE.InstancedMesh | null>(null);
  const [linksMesh, setLinksMesh] = useState<THREE.InstancedMesh | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [destError, setDestError] = useState<string | null>(null);
  const { smartCharacter } = useSmartObject();
  const {
    systemCalls: { getCanJumpStatus },
  } = useMUD();
  // console.warn("SMART CHARACTER: ", smartCharacter);
  const handlePathfinding = () => {
    if (!state.graph || !state.startNode || !state.destinationNode) return;
    // const path = dfsRoot(state, state.startNode.id, state.destinationNode.id);
    const path = buildPathTree(
      state,
      state.startNode.id,
      state.destinationNode.id
    );
    console.log("Path", path);
    if (!path || path.length === 0) {
      dispatch({
        type: MapActions.SET_PATH_ERROR,
        payload: "No path found",
      });
      return;
    }
    dispatch({
      type: MapActions.SET_PATH,
      payload: path,
    });
  };

  useEffectOnce(() => {
    // Add a label to the focused node
    const loader = new TTFLoader();
    const fontLoader = new FontLoader();
    loader.load("./disket_mono_regular.ttf", async (fnt) => {
      const coords = await getCoords(
        dispatch,
        smartCharacter.id,
        getCanJumpStatus
      );
      dispatch({
        type: MapActions.SET_INITIALIZED,
        payload: { font: fontLoader.parse(fnt), graphData: coords },
      });
    });
    dispatch({ type: MapActions.SET_SCALE_FACTOR, payload: getScaleFactor() });
    const graph = ForceGraph3D()(graphRef.current); // @todo: review
    dispatch({
      type: MapActions.SET_GRAPH,
      payload: graph,
    });
  });

  // handle query param routes.
  useEffect(() => {
    if (!location.search) return;
    // handle focusedNode
    const params = new URLSearchParams(location.search);
    const focusedNodeId = params.get(PathingParams.FocusedNode);
    const focusedNodeIdToUse = focusedNodeId && parseInt(focusedNodeId);
    if (focusedNodeId && parseInt(focusedNodeId)) {
      const focusedNode = state.nodes[parseInt(focusedNodeId)];
      if (focusedNode) {
        dispatch({
          type: MapActions.SET_FOCUSED_NODE,
          payload: focusedNode,
        });
      }
    }
    // handle destinationNode
    const destinationNodeId = params.get(PathingParams.DestinationNode);
    const destinationNodeIdToUse =
      destinationNodeId && parseInt(destinationNodeId);
    if (destinationNodeIdToUse) {
      const destinationNode = state.nodes[destinationNodeIdToUse];
      if (destinationNode) {
        dispatch({
          type: MapActions.SET_DESTINATION_NODE,
          payload: destinationNode,
        });
      }
    } else {
      if (focusedNodeIdToUse && !state.destinationNode) {
        const destinationNode = state.nodes[focusedNodeIdToUse];
        if (destinationNode) {
          dispatch({
            type: MapActions.SET_DESTINATION_NODE,
            payload: destinationNode,
          });
        } else {
          dispatch({
            type: MapActions.SET_START_NODE,
            payload: undefined,
          });
        }
      } else {
        dispatch({
          type: MapActions.SET_DESTINATION_NODE,
          payload: undefined,
        });
      }
    }

    // handle startNode
    const startNodeId = params.get(PathingParams.StartNode);
    const startNodeIdToUse = startNodeId && parseInt(startNodeId);
    if (startNodeIdToUse) {
      const startNode = state.nodes[startNodeIdToUse];
      if (startNode) {
        dispatch({
          type: MapActions.SET_START_NODE,
          payload: startNode,
        });
      }
    } else {
      // if the start node is not set then set the focused node as the start node
      if (focusedNodeIdToUse && !state.startNode) {
        const startNode = state.nodes[focusedNodeIdToUse];
        // if the focused node exist and startnode does not set it as the start node
        if (startNode) {
          dispatch({
            type: MapActions.SET_START_NODE,
            payload: startNode,
          });
        } else {
          dispatch({
            type: MapActions.SET_START_NODE,
            payload: undefined,
          });
        }
      } else {
        dispatch({
          type: MapActions.SET_START_NODE,
          payload: undefined,
        });
      }
    }
  }, [location.search, state.isDataInitialized]);

  const raycaster = new THREE.Raycaster();
  const mouse = new THREE.Vector2();
  const onMouseMove = (event: MouseEvent) => {
    // Normalize mouse position to range []
    const rect = graphRef.current.getBoundingClientRect();
    mouse.x = ((event.clientX - rect.left) / rect.width) * 2 - 1;
    mouse.y = -((event.clientY - rect.top) / rect.height) * 2 + 1;
  };

  const onMouseClick = () => {
    if (!state.graph || !nodeMesh) return;
    const graphScale = new THREE.Vector3(...SETTINGS.scale);
    const ray = raycaster.ray;

    // Apply scaling to ray origin and direction
    ray.origin.multiply(graphScale);
    ray.direction.multiply(graphScale).normalize();
    raycaster.setFromCamera(mouse, state.graph.camera());
    const intersects = raycaster.intersectObjects(
      state.graph.scene().children,
      true
    );
    // Iterate over the intersected objects
    intersects.forEach((intersection) => {
      const object = intersection.object;
      // console.log("Intersected object", object);
      // Make sure you're calling updateMatrixWorld on the object that needs it
      if (
        object instanceof THREE.InstancedMesh &&
        intersection &&
        intersection.instanceId &&
        object.geometry.attributes.meshId.getX(intersection.instanceId) ===
          MeshInstanceId.Nodes
      ) {
        object.updateMatrixWorld(true);
        const nodeId = nodeMesh.geometry.attributes.nodeId.getX(
          intersection.instanceId
        );
        const meshId = nodeMesh.geometry.attributes.meshId.getX(
          intersection.instanceId
        );
        console.log(
          `NodeID from mesh ${meshId}:`,
          nodeId,
          "instanceId:",
          intersection.instanceId
        );
        const selectedNode = state.nodes[intersection.instanceId];
        console.log("CLICKED ON NODE!:", object, intersection);
        const searchParams = new URLSearchParams(location.search);
        searchParams.set(PathingParams.FocusedNode, selectedNode.id.toString());
        const navLoc = createNavLocation(searchParams, "/map");
        navigate(navLoc);
        return;
      }
    });

    /** start:visualize */
    // Calculate ray endpoint
    // const rayEnd = ray.origin
    //   .clone()
    //   .add(ray.direction.clone().multiplyScalar(1000));
    // const rayPathGeometry = new THREE.BufferGeometry().setFromPoints([
    //   ray.origin.clone(),
    //   rayEnd,
    // ]);
    // const rayPathMaterial = new THREE.LineBasicMaterial({ color: 0xff0000 });
    // const rayPath = new THREE.Line(rayPathGeometry, rayPathMaterial);
    // state.graph.scene().add(rayPath);
    /** end:visualize */
  };

  // Resize the graph on window resize
  useEffect(() => {
    const resizeGraph = () => {
      if (state.graph && graphRef.current) {
        const { clientWidth, clientHeight } = graphRef.current;
        state.graph.width(clientWidth);
        state.graph.height(clientHeight);
      }
    };

    // Initial sizing
    resizeGraph();
    // Observe container size changes
    const resizeObserver = new ResizeObserver(() => resizeGraph());
    resizeObserver.observe(containerRef.current!);

    return () => {
      if (resizeObserver) resizeObserver.disconnect();
    };
  }, [state.graph, window.innerWidth, window.innerHeight]);

  // update anything
  useEffect(() => {
    if (!graphRef || !graphRef.current) return;
    if (state.graph) return;
    graphRef.current.innerHTML = "";
    const graph = ForceGraph3D({ controlType: "orbit" })(graphRef.current);
    dispatch({
      type: MapActions.SET_GRAPH,
      payload: graph,
    });
    // Add the raycasting listener
    graphRef.current.addEventListener("mousemove", onMouseMove);
    graphRef.current.addEventListener("click", onMouseClick);

    return () => {
      if (graphRef.current) {
        graphRef.current.removeEventListener("mousemove", onMouseMove);
        graphRef.current.removeEventListener("click", onMouseClick);
      }
    };
  }, []);

  useEffect(() => {
    if (!state.isDataInitialized) return;
    if (
      !state.graph ||
      state.nodes.length === 0
      // !initialFocusedCoords
    ) {
      return;
    }
    const validLinks = state.links.filter(
      (link) => state.nodes[link.source] && state.nodes[link.target]
    );
    console.time("Graph_Init");
    const colors = new Float32Array(state.nodes.length * 3);
    const linksColors = new Float32Array(validLinks.length * 3);
    const radii = new Float32Array(state.nodes.length);
    const linkOverlays = new Float32Array(validLinks.length);
    // Assign colors based on regionID
    console.time("buildInstanceMap");
    const map = buildInstanceMap(
      state.mapDisplayType,
      state.regionMap,
      state.activeAssembliesCountMap,
      state.smartGatesCountMap
    );
    console.timeEnd("buildInstanceMap");
    state.nodes.forEach(deriveColorsAndRadii(state, map, colors, radii));
    const linksMap = buildLinkInstanceMap(state);
    console.log("LinksMap", linksMap);
    state.links.forEach(
      deriveColorsAndOverlayOfLink(state, linksMap, linksColors, linkOverlays)
    );
    const material = new THREE.ShaderMaterial({
      vertexShader: `
        attribute vec3 instanceColor;
        attribute float instanceRadius; // New attribute for radius
        varying vec3 vColor;

        void main() {
          vColor = instanceColor;
          vec3 transformedPosition = position * instanceRadius; // Scale the node size
          vec4 mvPosition = modelViewMatrix * instanceMatrix * vec4(transformedPosition, 1.0);
          gl_Position = projectionMatrix * mvPosition;
        }
      `,
      fragmentShader: `
        varying vec3 vColor;

        void main() {
          float alpha = 0.7; // Set transparency
          gl_FragColor = vec4(vColor, alpha);
        }
      `,
      transparent: true,
    });
    // material.depthWrite = false; // Disable writing to depth buffer
    const instanceMesh = new THREE.InstancedMesh(
      new THREE.SphereGeometry(SETTINGS.nodeRadius, 16, 16), // Shared geometry
      material, // Shared material
      state.nodes.length // Number of instances
    );
    console.log("Instance mesh", instanceMesh);
    // Add color attribute to the geometry
    instanceMesh.geometry.setAttribute(
      "instanceColor",
      new THREE.InstancedBufferAttribute(colors, 3, false)
    );
    instanceMesh.geometry.setAttribute(
      "nodeId",
      new THREE.InstancedBufferAttribute(
        new Float32Array(state.nodes.map((node) => node.id)),
        1
      )
    );
    instanceMesh.geometry.setAttribute(
      "meshId",
      new THREE.InstancedBufferAttribute(
        new Float32Array(state.nodes.map(() => MeshInstanceId.Nodes)),
        1
      )
    );
    instanceMesh.geometry.setAttribute(
      "instanceRadius",
      new THREE.InstancedBufferAttribute(radii, 1)
    );

    setNodeMesh(instanceMesh);

    // Define the ShaderMaterial for links
    const linkMaterial = new THREE.ShaderMaterial({
      vertexShader: `
        attribute vec3 instanceColor;      // Per-link color
        attribute float instanceScale;     // Per-link scale (e.g., length)
        attribute float instanceHighlight;        // Per-link highlight flag
        varying vec3 vColor;               // Pass to fragment shader
        varying float vHighlight;           // Pass to fragment
    
        void main() {
          vColor = instanceColor;
          vHighlight = instanceHighlight;
    
          // Apply the scaling to the Y-axis, without flattening it
          vec3 transformedPosition = position;
    
          // Scale the geometry to match the instance scale
          // transformedPosition.z *= instanceScale; // Length scaling along the Y-axis
    
          // Ensure that the geometry is properly positioned and rotated
          vec4 mvPosition = modelViewMatrix * instanceMatrix * vec4(transformedPosition, 1.0);
          gl_Position = projectionMatrix * mvPosition;
        }
      `,
      fragmentShader: `
        varying vec3 vColor;
        varying float vHighlight;

        void main() {
          vec3 color = vColor; // Brighten highlighted links
          float alpha = 0.7; // Keep transparency consistent
          gl_FragColor = vec4(color, alpha);
        }
      `,
      transparent: true,
    });

    // Geometry for links: Use a cylinder aligned to the Z-axis
    const linkGeometry = new THREE.CylinderGeometry(
      SETTINGS.linkWidth, // Top radius
      SETTINGS.linkWidth, // Bottom radius
      1, // Base length of the cylinder
      8 // Radial segments
    ).rotateX(Math.PI / 2); // Rotate to align along Z-axis

    // InstancedMesh for links
    const linksInstancedMesh = new THREE.InstancedMesh(
      linkGeometry,
      linkMaterial,
      validLinks.length // Number of links
    );

    // Add color attribute to the geometry
    linksInstancedMesh.geometry.setAttribute(
      "instanceColor",
      new THREE.InstancedBufferAttribute(linksColors, 3, false)
    );
    const highlightArr = new Float32Array(validLinks.length);
    validLinks.forEach((link, i) => {
      highlightArr[i] = 1.0;
    });
    linksInstancedMesh.geometry.setAttribute(
      "instanceHighlight",
      new THREE.InstancedBufferAttribute(highlightArr, 1)
    );
    console.log("highlightArr", highlightArr);

    linksInstancedMesh.geometry.setAttribute(
      "meshId",
      new THREE.InstancedBufferAttribute(
        new Float32Array(state.nodes.map(() => MeshInstanceId.Links)),
        1
      )
    );
    setLinksMesh(linksInstancedMesh);

    state.graph
      .graphData(state as any)
      .cooldownTicks(0)
      .nodeAutoColorBy("relationsLength")
      .nodeRelSize(0)
      .nodeResolution(4)
      .linkResolution(4)
      .nodeLabel("")
      .width(graphRef.current?.clientWidth || window.innerWidth)
      .height(graphRef.current?.clientHeight || window.innerHeight)
      .linkThreeObjectExtend(false)
      .nodeThreeObjectExtend(false)
      .cameraPosition(deriveStartPosition(state.nodes))
      // @ts-ignore - This is a hack to get around the typescript definitions
      .nodeThreeObject(() => null)
      // @ts-ignore - This is a hack to get around the typescript definitions
      .linkThreeObject(() => null) // Disable link rendering
      .linkPositionUpdate(() => null) // Skip link positioning
      .nodeResolution(4)
      .linkWidth(0)
      .linkLabel("name")
      .enableNodeDrag(false)
      .scene().background = new THREE.Color(0x000000);

    state.graph.scene().add(instanceMesh, linksInstancedMesh);
    state.graph.scene().scale.set(...SETTINGS.scale);
    if (linksInstancedMesh.parent) {
      linksInstancedMesh.parent.rotation.set(0, 0, 0); // Try resetting the parent's rotation
      linksInstancedMesh.parent.updateMatrixWorld(true);
    }
    const upVector = new THREE.Vector3(0, 0, 1); // Default up orientation for the cylinder
    validLinks.forEach((link, i) => {
      const sourcePos = new THREE.Vector3(
        state.nodes[link.source].x,
        state.nodes[link.source].y,
        state.nodes[link.source].z
      );
      const targetPos = new THREE.Vector3(
        state.nodes[link.target].x,
        state.nodes[link.target].y,
        state.nodes[link.target].z
      );

      const center = new THREE.Vector3()
        .addVectors(sourcePos, targetPos)
        .multiplyScalar(0.5);
      const length = sourcePos.distanceTo(targetPos);
      const direction = new THREE.Vector3()
        .subVectors(targetPos, sourcePos)
        .normalize();

      // Check cylinder alignment
      const quaternion = new THREE.Quaternion();
      quaternion.setFromUnitVectors(upVector, direction);

      const matrix = new THREE.Matrix4();
      matrix.compose(
        center,
        quaternion,
        new THREE.Vector3(1, 1, length) // Ensure scaling matches the cylinder's alignment
      );

      linksInstancedMesh.setMatrixAt(i, matrix);
    });
    linksInstancedMesh.instanceMatrix.needsUpdate = true;
    const dummyObject = new THREE.Object3D();
    state.nodes.forEach((node, i) => {
      // create a node in the matrix for each node
      dummyObject.position.set(node.x, node.y, node.z);
      dummyObject.updateMatrix();
      // Ensure the scale and rotation are reset (if needed)
      dummyObject.scale.set(1, 1, 1);
      dummyObject.rotation.set(0, 0, 0);
      instanceMesh.setMatrixAt(i, dummyObject.matrix);
    });
    return () => {
      if (!state.graph) return;
      state.graph.pauseAnimation();
      if (graphRef.current) {
        (graphRef.current as any).innerHTML = ""; // Clean up the DOM element
      }
      state.graph.scene().remove(instanceMesh).remove(linksInstancedMesh);
    };
  }, [state.isDataInitialized]);

  useEffect(() => {
    // Used to update node radii and colors on highlight selection
    // ignore the first render
    if (!state.isDataInitialized) return;
    if (!state.graph) return;
    if (!state.font) return;
    // need to update the instanced mesh
    if (!nodeMesh || !linksMesh) return;
    console.log("Updating node mesh");
    console.time("NodeMeshUpdate");
    const colors = new Float32Array(state.nodes.length * 3);
    const radii = new Float32Array(state.nodes.length);
    // Assign colors based on regionID
    console.time("buildInstanceMap");
    const map = buildInstanceMap(
      state.mapDisplayType,
      state.regionMap,
      state.activeAssembliesCountMap,
      state.smartGatesCountMap
    );
    console.timeEnd("buildInstanceMap");
    const linksColors = new Float32Array(state.links.length * 3);
    const linkOverlays = new Float32Array(state.links.length);
    state.nodes.forEach(deriveColorsAndRadii(state, map, colors, radii));
    const linksMap = buildLinkInstanceMap(state);
    console.log("LinksMap", linksMap);
    state.links.forEach(
      deriveColorsAndOverlayOfLink(state, linksMap, linksColors, linkOverlays)
    );
    console.timeEnd("NodeMeshUpdate");
    // Add color attribute to the geometry
    nodeMesh.geometry.setAttribute(
      "instanceColor",
      new THREE.InstancedBufferAttribute(colors, 3, false)
    );
    nodeMesh.geometry.setAttribute(
      "instanceRadius",
      new THREE.InstancedBufferAttribute(radii, 1)
    );
    linksMesh.geometry.setAttribute(
      "instanceColor",
      new THREE.InstancedBufferAttribute(linksColors, 3, false)
    );
    linksMesh.geometry.setAttribute(
      "instanceHighlight",
      new THREE.InstancedBufferAttribute(linkOverlays, 1)
    );
  }, [state.mapDisplayType, state.path]);

  // Update the start or destination nodes.
  useEffect(() => {
    console.log("startNode node", state.startNode);
    console.log("destinationNode node", state.destinationNode);
    if (!state.font) return;
    if (
      !graphRef ||
      state.graph === null ||
      !state.graph! ||
      state.graph === null
    ) {
      console.error("No graph ref found");
      return;
    }
    if (!state.focusedNode && !state.focusedNode && !state.destinationNode)
      return;
    handlePathfinding();
    const pathTargets = new Map();
    state.path?.forEach((path) => pathTargets.set(path.target, true));
    const updatedNodes = state
      .graph!.graphData()
      .nodes.map(updateNode(state, pathTargets));
    state.graph!.graphData().nodes = updatedNodes;
    state.graph!.cameraPosition(
      deriveCameraPosition(state.focusedNode, state.destinationNode!), // new position
      deriveFocusPosition(state.focusedNode, state.destinationNode), // lookAt ({ x, y, z })
      1250 // ms transition duration);
    );
  }, [state.startNode, state.destinationNode]);

  useEffect(() => {
    console.log("Focused node", state.focusedNode);
    if (!state.font) return;
    if (
      !graphRef ||
      state.graph === null ||
      !state.graph! ||
      state.graph === null
    ) {
      console.error("No graph ref found");
      return;
    }
    if (!state.focusedNode && !state.focusedNode && !state.destinationNode)
      return;
    const pathTargets = new Map();
    state.path?.forEach((path) => pathTargets.set(path.target, true));
    const updatedNodes = state
      .graph!.graphData()
      .nodes.map(updateNode(state, pathTargets));
    state.graph!.graphData().nodes = updatedNodes;
    state.graph!.cameraPosition(
      deriveCameraPosition(state.focusedNode, state.destinationNode!), // new position
      deriveFocusPosition(state.focusedNode, state.destinationNode), // lookAt ({ x, y, z })
      1250 // ms transition duration);
    );
  }, [state.focusedNode]);
  useEffect(() => {
    if (!state.path || !state.path.length || !state.graph) return;
    const pathTargets = new Map();
    state.path?.forEach((path) => pathTargets.set(path.target, true));
    const updatedNodes = state
      .graph!.graphData()
      .nodes.map(updateNode(state, pathTargets));
    state.graph!.graphData().nodes = updatedNodes;
    state.graph!.cameraPosition(
      deriveCameraPosition(state.focusedNode, state.destinationNode!), // new position
      deriveFocusPosition(state.focusedNode, state.destinationNode), // lookAt ({ x, y, z })
      1250 // ms transition duration);
    );
  }, [state.path]);

  useEffect(() => {
    if (!graphRef || !graphRef.current || !state.graph) return;
    // Add the raycasting listener
    graphRef.current.addEventListener("mousemove", onMouseMove);
    graphRef.current.addEventListener("click", onMouseClick);

    return () => {
      graphRef.current?.removeEventListener("mousemove", onMouseMove);
      graphRef.current?.removeEventListener("click", onMouseClick);
    };
  }, [state.graph?.graphData().nodes]);
  const selectedDestinationNode = state.destinationNode
    ? state.nodes.find((node) => node.id === state.destinationNode!.id)
    : undefined;

  return (
    <div className="bg-crude" id="map-view-container" ref={containerRef}>
      <div className="Quantum-Container Title">{`${state.type} Map`}</div>
      <div className="Quantum-Container">
        <span className="text-sm">{`This Map contains all of the systems in the game and their
        ${state.type} links (<${state.type === "NPC Gate" ? "300" : "5"} Light Years distant). To search for a system, just enter the name of the system below and the map will focus on it. Grey links are NPC Gates - Orange links are smart gates.`}</span>
        <div className="flex-row py-2">
          <span>Color By</span>
          {Object.entries(mapDisplayTypeLabel)
            .filter(([key]) => mapFiltersEnabled[key as MapDisplayType])
            .map(([displayTypeKey, label], idx) => {
              // console.log("Display type key", label, displayTypeKey, mapDisplayTypeAsync[displayTypeKey as MapDisplayType], !state.isAsyncFilteringEnabled);
              // disable input based on if the field is async and whether or not async initiatialization is complete
              return (
                <div key={idx} className="flex flex-row">
                  <input
                    type="radio"
                    id={displayTypeKey}
                    name="displayType"
                    value={
                      state.mapDisplayType === displayTypeKey
                        ? displayTypeKey
                        : ""
                    }
                    disabled={
                      mapDisplayTypeAsync[displayTypeKey as MapDisplayType] &&
                      !state.isAsyncFilteringEnabled
                    }
                    onChange={(e) => {
                      dispatch({
                        type: MapActions.SET_MAP_DISPLAY_TYPE,
                        payload: displayTypeKey as MapDisplayType,
                      });
                    }}
                  />
                  <label htmlFor={displayTypeKey}>{label}</label>
                </div>
              );
            })}
        </div>
      </div>
      <div className="Quantum-Container Title">{`Data`}</div>
      <div className="Quantum-Container">
        <div className="flex flex-row py-1 w-full justify-between">
          <div
            className="flex flex-col px-1 w-full"
            style={{ justifyContent: "end " }}
          >
            {state.startNode && (
              <div className="flex flex-col text-xs">
                <span>{`System Name: ${state.startNode.a_name}`}</span>
                <span>{`System ID: ${state.startNode.id}`}</span>
                <span>{`System Coordinates: (${state.startNode.locationX},${state.startNode.locationY},${state.startNode.locationZ})`}</span>
              </div>
            )}
            <span className="text-sm py-2">Input System Name</span>
            {error !== null && (
              <span className="text-xs">
                Error searching for system. {error}
              </span>
            )}

            <EveInput
              inputType="string"
              fieldName=""
              defaultValue=""
              placeholder="System name - (e.g. Cydias)"
              onChange={(name: string | number | null) => {
                const searchParams = new URLSearchParams(location.search);
                if (!name) {
                  searchParams.delete(PathingParams.StartNode);
                  navigate(createNavLocation(searchParams, "/map"));
                  return;
                }
                const node = state.nodesMap[name.toString().toLowerCase()];
                if (!node || !node.x || !node.y || !node.z) {
                  setError(`No solar system found with system name: ${name}`);
                  searchParams.delete(PathingParams.StartNode);
                  navigate(createNavLocation(searchParams, "/map"));
                  return;
                }
                setError(null);
                searchParams.set("startNode", node.id.toString());
                navigate(createNavLocation(searchParams, "/map"));
              }}
            />
          </div>
          <div
            className="flex flex-col px-1 w-full"
            style={{ justifyContent: "end " }}
          >
            {state.destinationNode && selectedDestinationNode && (
              <div className="flex flex-col text-xs">
                <span>{`System Name: ${state.destinationNode.a_name}`}</span>
                <span>{`System ID: ${state.destinationNode.id}`}</span>
                <span>{`System Coordinates: (${state.destinationNode.locationX},${state.destinationNode.locationY},${state.destinationNode.locationZ})`}</span>
              </div>
            )}{" "}
            <span className="text-sm py-2">Input Destination System Name</span>
            {destError !== null && (
              <span className="text-xs">
                Error searching for system. {destError}
              </span>
            )}
            <EveInput
              inputType="string"
              fieldName=""
              defaultValue=""
              placeholder="Destination System name - (e.g. Cydias)"
              onChange={(name: string | number | null) => {
                const searchParams = new URLSearchParams(location.search);
                if (!name) {
                  searchParams.delete(PathingParams.DestinationNode);
                  navigate(createNavLocation(searchParams, "/map"));
                  return;
                }
                const node = state.nodesMap[name.toString().toLowerCase()];
                if (!node || !node.x || !node.y || !node.z) {
                  setDestError(
                    `No solar system found with system name: ${name}`
                  );
                  searchParams.delete(PathingParams.DestinationNode);
                  navigate(createNavLocation(searchParams, "/map"));
                  return;
                }
                setDestError(null);
                searchParams.set(
                  PathingParams.DestinationNode,
                  node.id.toString()
                );
                navigate(createNavLocation(searchParams, "/map"));
              }}
            />
          </div>
        </div>
        {state.destinationNode && state.startNode && (
          <div className="flex flex-col text-sm">
            <span>{`Connection: ${state.startNode.a_name} => ${state.destinationNode.a_name}`}</span>
            <span>{`System ID: ${state.startNode.solarSystemId} => ${state.destinationNode.solarSystemId}`}</span>
            <span>{`Distance: ${lightYearsBetweenTwo3DPoints(
              {
                x: BigInt(state.startNode.locationX?.toString()!),
                y: BigInt(state.startNode.locationY?.toString()!),
                z: BigInt(state.startNode.locationZ?.toString()!),
              },
              {
                x: BigInt(state.destinationNode.locationX?.toString()!),
                y: BigInt(state.destinationNode.locationY?.toString()!),
                z: BigInt(state.destinationNode.locationZ?.toString()!),
              }
            ).toFixed(7)}LY`}</span>
            {state.pathError && <span>{state.pathError}</span>}
            {state.path &&
              state.path.length > 0 &&
              (() => {
                const jumpLocations = state.path.map((pathItem) => ({
                  ...state.nodes[pathItem.target],
                }));
                return (
                  <div className="flex flex-row" style={{ flexWrap: "wrap" }}>
                    {`Gate Jumps (${state.path.length - 1}): `}{" "}
                    {jumpLocations.map((loc, idx) => (
                      <div className="flex" key={`jumplocs-${idx}`}>
                        {" "}
                        {idx > 0 ? (
                          <div className="px-2">{` => `}</div>
                        ) : (
                          <div className="px-2" />
                        )}{" "}
                        <div
                          className=""
                          style={{
                            color: `#${COLOR_THEME.baseSystemColor.toString(16)}`,
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            const searchParams = new URLSearchParams(
                              location.search
                            );
                            searchParams.set(
                              PathingParams.DestinationNode,
                              loc.id.toString()
                            );
                            dispatch({
                              type: MapActions.SET_FOCUSED_NODE,
                              payload: loc,
                            });
                          }}
                          key={`path-item-${idx}`}
                        >
                          {loc.a_name}
                        </div>
                      </div>
                    ))}
                  </div>
                );
              })()}
          </div>
        )}
      </div>
      {graphRef ? (
        <div className="Quantum-Container">
          <div
            ref={graphRef as any}
            style={{
              maxWidth: "100%",
              maxHeight: "100%",
              position: "relative",
            }}
          />
        </div>
      ) : (
        <div>Loading....</div>
      )}
    </div>
  );
};

export default GalaxyMap;
