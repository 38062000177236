import axios, { AxiosInstance, AxiosResponse } from "axios";
import memoize from "memoizee";
import { GateLinkResponse, SmartAssemblyLocationsResponse, SmartAssemblyType, SmartAssemblyTypesResponse } from "./types";
import { SmartObjectId, SmartObjectType, SolarSystemId } from "@/types";
// import dotenv from "dotenv";
// dotenv.config();

export default class IndexerHTTPService {
  private static instance: IndexerHTTPService;
  private isInitialized: boolean = false;
  private client?: AxiosInstance;
  private defaultBaseURL: string =
    "https://indexer.mud.garnetchain.com";
    private worldAddress: string = import.meta.env.WORLD_ADDRESS ||
    "0x7fe660995b0c59b6975d5d59973e2668af6bb9c5";

  private constructor() {
    this.initialize();
  }

  public static getInstance(): IndexerHTTPService {
    if (!IndexerHTTPService.instance) {
        IndexerHTTPService.instance = new IndexerHTTPService();
    }
    return IndexerHTTPService.instance;
  }

  private initialize = () => {
    if (this.isInitialized && this.client) return;
    this.isInitialized = true;
    console.warn(
      "Initializing IndexerHTTPService with baseUrl: ",
      this.defaultBaseURL
    );
    this.client = axios.create({
      baseURL: this.defaultBaseURL,
      timeout: 5000,
    });
  };

  public getClient = (): AxiosInstance => {
    if (!this.isInitialized || !this.client) {
      this.initialize();
    }
    return this.client as AxiosInstance;
  };

  public getSmartAssemblyLocations = async (): Promise<Record<SmartObjectId, { solarSystemId: SolarSystemId, x: bigint, y: bigint, z: bigint}>> => {
    const data =[{"address":this.worldAddress,"query":"SELECT \"smartObjectId\", \"solarSystemId\", \"x\", \"y\", \"z\" FROM eveworld__LocationTable WHERE solarSystemId != 0;"}]
    const resp: AxiosResponse<SmartAssemblyLocationsResponse> | undefined = await this.getClient().post(`/q`,data).catch(err => {
        console.error(`[IndexerHTTPService.getSmartAssemblyLocations]: Error fetching smart gates from indexer`, err);
        return undefined
    }); 
    if (!resp) {
        return {}
    }
    return resp.data.result[0].reduce<Record<SmartObjectId, { solarSystemId: SolarSystemId, x: bigint, y: bigint, z: bigint }>>((acc, link, i) => {
        if (i === 0) return acc;
        const key = link[0].toString() as SmartObjectId;
        if (!link[0] || !link[1] || !link[2] || !link[3] || !link[4]) return acc;
        const solarSystemId = link[1].toString() as SolarSystemId;
        const x = link[2].toString();
        const y = link[3].toString();
        const z = link[4].toString();
        acc[key] = { solarSystemId: solarSystemId, x: BigInt(x), y: BigInt(y), z: BigInt(z)};
        return acc;
    }, {});
  }
  

  public getSmartObjectTypes = async (): Promise<Record<SmartObjectId, SmartObjectType>> => {
    const data = [{"address":this.worldAddress,"query":"SELECT \"smartObjectId\", \"smartAssemblyType\" FROM eveworld__SmartAssemblyTab;"}]
    const resp: AxiosResponse<SmartAssemblyTypesResponse> | undefined = await this.getClient().post(`/q`,data).catch(err => {
        console.error(`[IndexerHTTPService.getSmartObjectTypes]: Error fetching smart object types from indexer`, err);
        return undefined
    }); 
    if (!resp) {
        return {}
    }
    return resp.data.result[0].reduce<Record<SmartObjectId, SmartObjectType>>((acc, link, i) => {
        if (i === 0) return acc;
        const key = link[0].toString() as SmartObjectId;
        if (!link[0] || !link[1]) return acc;
        const smartAssemblyType = link[1];
        acc[key] = parseInt(smartAssemblyType.toString()) as unknown as SmartObjectType;
        return acc;
    }, {});
  }

  public getSmartGateLinks = async (): Promise<Record<string, {source: BigInt, destination: BigInt}>> => {
    const data = [{"address": this.worldAddress ,"query":"SELECT \"sourceGateId\", \"destinationGateId\", \"isLinked\" FROM eveworld__SmartGateLinkTab;"}]
    const resp: AxiosResponse<GateLinkResponse> | undefined = await this.getClient().post(`/q`,data).catch(err => {
        console.error(`[IndexerHTTPService.getSmartGateLinks]: Error fetching smart gates from indexer`, err);
        return undefined
    }); 
    if (!resp) {
        return {}
    }
    return resp.data.result[0].reduce<Record<string, { source: BigInt, destination: BigInt }>>((acc, link, i) => {
        if (i === 0) return acc;
        if (!link[2]) return acc;
        const key = link[0].toString();
        if (!link[0] || !link[1]) return acc;
        const sourceObjectId = link[0].toString();
        const destinationObjectId = link[1].toString();
        acc[key] = {source: BigInt(sourceObjectId), destination: BigInt(destinationObjectId)};
        return acc;
    }, {});

  }
}