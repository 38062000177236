/*
 * The MUD client code is built on top of viem
 * (https://viem.sh/docs/getting-started.html).
 * This line imports the functions we need from it.
 */
import {
  createPublicClient,
  fallback,
  webSocket,
  http,
  Hex,
  ClientConfig,
  Chain,
  erc20Abi,
  WalletClient,
  getContract,
} from "viem";
import { mergeAbis } from "@ponder/utils";
import { syncToZustand } from "@latticexyz/store-sync/zustand";
import { getNetworkConfig } from "./getNetworkConfig";
import IWorldAbi from "../abi/IWorld.abi.json";
import IOrderbookDexSystemAbi from "../abi/IOrderbookDexSystem.abi.json";
import IOrdersSystemAbi from "../abi/IOrdersSystem.abi.json";
import OrderbookDexSystemAbi from "../abi/OrderbookDexSystem.abi.json";
import OrderbookDexErrorsAbi from "../abi/OrderbookDexErrors.abi.json";
import IStructureUtilsSystemAbi from "../abi/IStructureUtilsSystem.abi.json";
import { transportObserver, ContractWrite } from "@latticexyz/common";
import { Subject, share } from "rxjs";

/*
 * Import our MUD config, which includes strong types for
 * our tables and other config options. We use this to generate
 * things like RECS components and get back strong types for them.
 *
 * See https://mud.dev/templates/typescript/contracts#mudconfigts
 * for the source of this information.
 */
import mudConfig from "./mud.config";

export type SetupNetworkResult = Awaited<ReturnType<typeof setupNetwork>>;

export const setupNetwork = async (
  walletClient: WalletClient
): Promise<any> => {
  const networkConfig = await getNetworkConfig();
  const mergedAbi = mergeAbis([
    IWorldAbi as any,
    IOrderbookDexSystemAbi,
    IOrdersSystemAbi,
    OrderbookDexSystemAbi,
    OrderbookDexErrorsAbi,
    IStructureUtilsSystemAbi,
  ]);
  /*
   * Create a viem public (read only) client
   * (https://viem.sh/docs/clients/public.html)
   */

  const fallbackTransport = fallback([webSocket(), http()]);
  const clientOptions = {
    chain: networkConfig.chain as Chain,
    transport: transportObserver(fallbackTransport),
    pollingInterval: 1000,
    account: walletClient.account,
  } as const satisfies ClientConfig;

  const publicClient = createPublicClient(clientOptions);

  /*
   * Create an observable for contract writes that we can
   * pass into MUD dev tools for transaction observability.
   */
  const write$ = new Subject<ContractWrite>();

  /*
   * Create an object for communicating with the deployed World.
   */
  console.log("World Address: ", networkConfig.worldAddress);
  const worldContract = getContract({
    address: networkConfig.worldAddress as Hex,
    abi: mergedAbi,
    client: { public: publicClient, wallet: walletClient },
  });

  /**
   * Create an object for communicating with the Orderbook DEX Contract.
   */

  /*
   * Create an object for communicating with the deployed ERC20 contract.
   */
  console.log("Token Address: ", import.meta.env.VITE_ERC20_TOKEN_ADDRESS);
  const erc20Contract = getContract({
    address: import.meta.env.VITE_ERC20_TOKEN_ADDRESS as Hex,
    abi: erc20Abi,
    client: { public: publicClient, wallet: walletClient },
  });
  /*
   * Sync on-chain state into RECS and keeps our client in sync.
   * Uses the MUD indexer if available, otherwise falls back
   * to the viem publicClient to make RPC calls to fetch MUD
   * events from the chain.
   */
  // console.debug("Syncing to Zustand");
  // const {
  //   tables,
  //   useStore,
  //   latestBlock$,
  //   storedBlockLogs$,
  //   waitForTransaction,
  // } = await syncToZustand({
  //   publicClient,
  //   config: mudConfig,
  //   address: networkConfig.worldAddress as Hex,
  //   startBlock: BigInt(networkConfig.initialBlockNumber),
  // });

  return {
    publicClient,
    walletClient: walletClient,
    worldContract,
    erc20Contract,
    write$: write$.asObservable().pipe(share()),
  };
};
