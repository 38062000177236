import * as THREE from "three";
import { TextGeometry } from "three/examples/jsm/geometries/TextGeometry";

import { COLOR_THEME, SETTINGS } from "./constants";
import { CoordsWithMetaAndRelations } from "./types";
import { IMapState } from "./reducer";
import { distanceBetweenTwo3DPoints, distanceBetweenTwoPoints } from "@/utils";
import { NodeObject } from "@/types";

export const DYNAMIC_SHADER_MATERIAL = new THREE.ShaderMaterial({
  vertexShader: `
    attribute vec3 instanceColor;
    varying vec3 vColor;

    void main() {
      vColor = instanceColor;
      vec4 mvPosition = modelViewMatrix * instanceMatrix * vec4(position, 1.0);
      gl_Position = projectionMatrix * mvPosition;
    }
  `,
  fragmentShader: `
    varying vec3 vColor;

    void main() {
      gl_FragColor = vec4(vColor, 1.0);
    }
  `,
});

export const updateNode =
  (state: IMapState, pathTargets: Map<number, boolean>) =>
  // @ts-ignore
  (node: THREE.Object & NodeObject & CoordsWithMetaAndRelations) => {
    const obj = node.__threeObj;
    if (!node || !obj || !node.a_name) {
      console.log("ERROR WITH UPDATING DATA IN PLACE", node, obj, node.a_name);
      return;
    }
    if (state.startNode && node.id === state.startNode.id) {
      // override the node with a different colored sphere for emphasis
      const selectionSphere = new THREE.Mesh(
        new THREE.SphereGeometry(0.1, 32, 32),
        new THREE.MeshBasicMaterial({
          color: COLOR_THEME.selectedSystemColor,
          transparent: true,
          opacity: COLOR_THEME.selectedSystemOpacity,
        })
      );

      const textGeometry = new TextGeometry(node.a_name, {
        font: state.font!,
        size: SETTINGS.labelSize,
        height: SETTINGS.labelSize / 5,
        depth: SETTINGS.labelSize / 10,
      });

      const textMaterial = new THREE.MeshBasicMaterial({
        color: COLOR_THEME.selectedSystemColor,
      });
      const textMesh = new THREE.Mesh(textGeometry, textMaterial);
      textMesh.scale.set(...SETTINGS.scale);

      textMesh.position.set(...SETTINGS.labelPosition); // Position above the sphere
      // Add an update callback to make the text always face the camera
      textMesh.onBeforeRender = (renderer, scene, camera) => {
        textMesh.quaternion.copy(camera.quaternion); // Align with camera orientation
      };
      textMesh.renderOrder = 499; // render text after the node
      selectionSphere.add(textMesh);
      // add a halo-like sphere around the start node
      const selectionRadis =
        SETTINGS.defaultSelectedNodeRadiusInMeters * state.scaleFactor!;
      const haloSphere = new THREE.Mesh(
        new THREE.SphereGeometry(selectionRadis, 64, 64),
        new THREE.MeshBasicMaterial({
          color: COLOR_THEME.selectedSystemHaloColor,
          transparent: true,
          opacity: COLOR_THEME.selectedSystemHaloOpacity,
        })
      );
      haloSphere.renderOrder = 1; // render halo sphere after the node
      // add the halo to the scene
      selectionSphere.add(haloSphere);
      obj.add(selectionSphere); // Return the sphere to attach it to the node
    }
    // set destination node color red and add a halo
    if (state.destinationNode && node.id === state.destinationNode.id) {
      // override the node with a different colored sphere for emphasis
      const selectionSphere = new THREE.Mesh(
        new THREE.SphereGeometry(0.1, 32, 32),
        new THREE.MeshBasicMaterial({
          color: COLOR_THEME.selectedDestinationSystemColor,
          // transparent: true,
          opacity: COLOR_THEME.selectedDestinationSystemOpacity,
        })
      );
      const textGeometry = new TextGeometry(node.a_name, {
        font: state.font!,
        size: SETTINGS.labelSize,
        height: SETTINGS.labelSize / 5,
        depth: SETTINGS.labelSize / 10,
      });

      const textMaterial = new THREE.MeshBasicMaterial({
        color: COLOR_THEME.selectedDestinationSystemColor,
        opacity: COLOR_THEME.selectedDestinationSystemOpacity,
      });
      const textMesh = new THREE.Mesh(textGeometry, textMaterial);
      textMesh.scale.set(...SETTINGS.scale);
      textMesh.position.set(...SETTINGS.labelPosition); // Position above the sphere
      // Add an update callback to make the text always face the camera
      textMesh.onBeforeRender = (renderer, scene, camera) => {
        textMesh.quaternion.copy(camera.quaternion); // Align with camera orientation
      };
      textMesh.renderOrder = 500; // render text after the node
      selectionSphere.add(textMesh);
      // add a halo-like sphere around the focused node
      const selectionRadis =
        SETTINGS.defaultSelectedNodeRadiusInMeters * state.scaleFactor!;

      const haloSphere = new THREE.Mesh(
        new THREE.SphereGeometry(selectionRadis, 64, 64),
        new THREE.MeshBasicMaterial({
          color: COLOR_THEME.selectedDestinationSystemHaloColor,
          transparent: true,
          opacity: COLOR_THEME.selectedDestinationSystemHaloOpacity,
        })
      );
      haloSphere.renderOrder = 1; // render halo sphere after the node
      // add the halo to the scene
      selectionSphere.add(haloSphere);

      obj.add(selectionSphere); // Return the sphere to attach it to the node
      node.__threeObj = obj;
      return node;
    } else if (state.startNode && state.mapOfClosestNodes[node.id]) {
      // if we're in the same region, but not the same constellation, color the node differently
      const textGeometry = new TextGeometry(node.a_name, {
        font: state.font!,
        size: SETTINGS.labelSize,
        height: SETTINGS.labelSize / 5,
        depth: SETTINGS.labelSize / 10,
      });

      const textMaterial = new THREE.MeshBasicMaterial({
        color: COLOR_THEME.selectedConstellationColor,
      });
      const textMesh = new THREE.Mesh(textGeometry, textMaterial);
      textMesh.scale.set(...SETTINGS.scale);

      textMesh.position.set(...SETTINGS.labelPosition); // Position above the sphere
      // Add an update callback to make the text always face the camera
      textMesh.onBeforeRender = (renderer, scene, camera) => {
        // set the text to always face directly the camera. (Billboard)
        textMesh.quaternion.copy(camera.quaternion); // Align with camera orientation
      };
      textMesh.renderOrder = 500; // render text after the node
      obj.add(textMesh);
      node.__threeObj = obj;
      return node;
    } else if (
      (state.focusedNode && state.focusedNode.id === node.id) ||
      (pathTargets && pathTargets.has(node.id))
    ) {
      // if the node lies upon the path, add a label to it
      const textGeometry = new TextGeometry(node.a_name, {
        font: state.font!,
        size: SETTINGS.labelSize,
        height: SETTINGS.labelSize / 5,
        depth: SETTINGS.labelSize / 10,
      });

      const textMaterial = new THREE.MeshBasicMaterial({
        color: COLOR_THEME.selectedConstellationColor,
      });
      const textMesh = new THREE.Mesh(textGeometry, textMaterial);
      textMesh.scale.set(...SETTINGS.scale);

      textMesh.position.set(...SETTINGS.labelPosition); // Position above the sphere
      // Add an update callback to make the text always face the camera
      textMesh.onBeforeRender = (renderer, scene, camera) => {
        // set the text to always face directly the camera. (Billboard)
        textMesh.quaternion.copy(camera.quaternion); // Align with camera orientation
      };
      textMesh.renderOrder = 500; // render text after the node
      obj.add(textMesh);
      node.__threeObj = obj;
      return node;
    }
    // otherwise - remove the previously added selection spheres
    obj.children.forEach((child: any) => {
      if (child instanceof THREE.Mesh) {
        obj.remove(child);
      }
    });
    node.__threeObj = obj;
    return node;
  };
