export interface SimpleCoordsWithMeta {
  solarSystemId: number;
  a_name: string;
  relations: {
    name: string;
    solarSystemId: number;
    distance: string;
  }[];
  locationX: number;
  locationY: number;
  locationZ: number;
}

export enum GateTypes {
  SMART_GATE = "SmartGate",
  NPC_GATE = "NpcGate",
}

export interface SimpleMetaWithoutCoords {
  id: number;
  name: string;
  regionID: number;
  constellationID: number;
  relations: {
    name: string;
    solarSystemId: number;
    distance: string;
  }[];
}
export type CoordsWithMeta = SimpleMetaWithoutCoords &
  Coords & {
    locationX?: number;
    locationY?: number;
    locationZ?: number;
    fx?: number;
    fy?: number;
    fz?: number;
};
  
export type CoordsWithMetaAndRelations = CoordsWithMeta & {
  solarSystemId: number;
  a_name: string;
}

export interface Coords {
  x: number;
  y: number;
  z: number;
}