import { ForceGraph3DInstance } from "3d-force-graph";
import { Font } from "three/examples/jsm/loaders/FontLoader";
import { CoordsWithMetaAndRelations } from "./types";
import { extractMapOfClosestSystems } from "./utils";

export interface IMapState {
  font: undefined | Font;
  nodes: any[];
  links: { source: number; target: number }[];
  type: string;
  scaleFactor?: number;
  mapDisplayType: MapDisplayType;
  nodesMap: { [key: string]: any };
  nodesMapById: { [key: string]: any };
  constellationMap: { [key: string]: number[] };
  regionMap: { [key: string]: number[] };
  activeAssembliesCountMap: { [key: string]: BigInt[] };
  smartGatesCountMap: { [key: string]: BigInt[] };
  smartGatesLinksMap: {
    [key: string]: { source: BigInt; destination: BigInt };
  };
  mapOfClosestNodes: { [key: string | number]: boolean };
  path: { target: number; linkId: number }[];
  pathError: string | undefined;
  focusedNode: CoordsWithMetaAndRelations | undefined;
  startNode: CoordsWithMetaAndRelations | undefined;
  destinationNode: CoordsWithMetaAndRelations | undefined;
  graph: ForceGraph3DInstance | null;
  isInitialized: boolean;
  isDataInitialized: boolean;
  isAsyncFilteringEnabled: boolean;
}

export const enum MapDisplayType {
  NONE = "none",
  REGION = "region",
  NPC_STATION = "npc_station",
  ACTIVE_ASSEMBLIES_COUNT = "active_assemblies_count",
  SMART_GATES_COUNT = "smart_gates_count",
  RIFTS = "rifs",
}

export const enum MapActions {
  SET_IS_INITIALIZED = "SET_IS_INITIALIZED",
  SET_INITIALIZED = "SET_INITIALIZED",
  SET_GRAPH = "SET_GRAPH",
  SET_FOCUSED_NODE = "SET_FOCUSED_NODE",
  SET_START_NODE = "SET_START_NODE",
  SET_DESTINATION_NODE = "SET_DESTINATION_NODE",
  SET_MAP_DISPLAY_TYPE = "SET_MAP_DISPLAY_TYPE",
  SET_SCALE_FACTOR = "SET_SCALE_FACTOR",
  SET_SMART_GATES_MAP = "SET_SMART_GATES_MAP",
  SET_ACTIVE_ASSEMBLIES_COUNT_MAP = "SET_ACTIVE_ASSEMBLIES_COUNT_MAP",
  SET_ACTIVE_SMART_GATES_COUNT_MAP = "SET_ACTIVE_SMART_GATES_COUNT_MAP",
  SET_ASYNC_FILTERS_IS_ENABLED = "SET_ASYNC_FILTERS_IS_ENABLED",
  SET_PATH = "SET_PATH",
  SET_PATH_ERROR = "SET_PATH_ERROR",
}

export const initialMapReducerState: IMapState = {
  font: undefined,
  nodes: [],
  links: [],
  type: "",
  scaleFactor: undefined,
  mapDisplayType: MapDisplayType.NPC_STATION,
  nodesMap: {},
  nodesMapById: {},
  constellationMap: {},
  regionMap: {},
  activeAssembliesCountMap: {},
  smartGatesCountMap: {},
  smartGatesLinksMap: {},
  mapOfClosestNodes: {},
  path: [],
  pathError: undefined,
  focusedNode: undefined,
  startNode: undefined,
  destinationNode: undefined,
  isInitialized: false,
  isDataInitialized: false,
  isAsyncFilteringEnabled: false,
  graph: null,
};

export const mapReducer = (
  state = initialMapReducerState,
  action: { type: MapActions; payload: any }
): IMapState => {
  switch (action.type) {
    case MapActions.SET_GRAPH:
      return {
        ...state,
        graph: action.payload,
      };
    case MapActions.SET_INITIALIZED:
      return {
        ...state,
        font: action.payload.font,
        nodes: action.payload.graphData.nodes,
        links: action.payload.graphData.links,
        type: action.payload.graphData.type,
        nodesMap: action.payload.graphData.nodesMap,
        nodesMapById: action.payload.graphData.nodesMapById,
        constellationMap: action.payload.graphData.constellationMap,
        regionMap: action.payload.graphData.regionMap,
        isDataInitialized: true,
      };
    case MapActions.SET_FOCUSED_NODE:
      return {
        ...state,
        focusedNode: action.payload,
      };
    case MapActions.SET_START_NODE:
      return {
        ...state,
        path: [],
        startNode: action.payload,
        focusedNode: state.focusedNode ?? action.payload,
        mapOfClosestNodes: action.payload
          ? extractMapOfClosestSystems(action.payload, state.nodes)
          : {},
      };
    case MapActions.SET_DESTINATION_NODE:
      return {
        ...state,
        path: [],
        destinationNode: action.payload,
      };

    case MapActions.SET_MAP_DISPLAY_TYPE:
      return {
        ...state,
        mapDisplayType: action.payload,
      };
    case MapActions.SET_SCALE_FACTOR:
      return {
        ...state,
        scaleFactor: action.payload,
      };
    case MapActions.SET_ACTIVE_ASSEMBLIES_COUNT_MAP:
      return {
        ...state,
        activeAssembliesCountMap: action.payload,
      };
    case MapActions.SET_ACTIVE_SMART_GATES_COUNT_MAP:
      return {
        ...state,
        smartGatesCountMap: action.payload,
      };
    case MapActions.SET_ASYNC_FILTERS_IS_ENABLED: {
      return {
        ...state,
        isAsyncFilteringEnabled: true,
      };
    }
    case MapActions.SET_SMART_GATES_MAP:
      return {
        ...state,
        smartGatesLinksMap: action.payload,
      };
    case MapActions.SET_PATH:
      return {
        ...state,
        pathError: undefined,
        path: action.payload,
      };
    case MapActions.SET_PATH_ERROR:
      return {
        ...state,
        path: [],
        pathError: action.payload,
      };
    default:
      return state;
  }
};
