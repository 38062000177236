import memoize from 'memoizee';

import { IMapState } from "./reducer";
import { buildAdjacencyList, calculateLinksDistances } from "./utils";

export const traverseTree = (adjacencyList: Record<number, { target: number, distance: number, linkId: number }[]>, origin: number, termination: number, previousNode: number, currentNode: number, path: { target: number, linkId: number }[], completedPaths: { target: number, linkId: number }[][], depth: number, visited: Map<number, number>) => {
    visited.set(currentNode, depth);
    if (depth > 50) {
        console.log('depth exceeded');
        return;
    }
    if (currentNode === termination) {
        completedPaths.push(path);
        return;
    }

    const children = adjacencyList[currentNode];
    if (!children) {
        return;
    }
    for (let i = 0; i < children.length; i++) {
        const child = children[i];
        if (child.target !== previousNode && (!visited.has(child.target) || (visited.get(child.target) || Infinity) > depth + 1)) {
            traverseTree(adjacencyList, origin, termination, currentNode, child.target, [...path, { target: child.target, linkId: child.linkId }], completedPaths, depth + 1, visited);
        }
    }
}

const memoizedTreeTraversal = memoize((adjacencyList: Record<number, { target: number, distance: number, linkId: number }[]>, start: number, end: number, visited: Map<number, number>) => {
    console.log('memoizedTreeTraversal invoked');
    const completedPaths: { target: number, linkId: number }[][] = [];
    console.time('buildPathTree');
    traverseTree(adjacencyList, start, end, -1, start, [{ target: start, linkId: -1 }], completedPaths, 0, visited);
    console.timeEnd('buildPathTree');
    console.log('completedPaths', completedPaths);
    const shortestPath = completedPaths.reduce((acc, path) => {
        if (acc.length === 0 || path.length < acc.length) {
            return path;
        }
        return acc;
    }, [])
    console.log('shortestPath', shortestPath, "length: ", (shortestPath || []).length);
    return shortestPath;
}, { maxAge: 1000 * 60, promise: false})

export const buildPathTree = (state: IMapState, start: number, end: number) => {
    const visited = new Map()
    const adjacencyList = buildAdjacencyList(calculateLinksDistances(state));
    console.log("invoke memoizedTreeTraversal");
    return memoizedTreeTraversal(adjacencyList, start, end, visited);
}