/*
 * This file sets up all the definitions required for a MUD client.
 */

import { WalletClient } from "viem";
import { createSystemCalls } from "./createSystemCalls";
import { setupNetwork } from "./setupNetwork";

export type SetupResult = Awaited<ReturnType<typeof setup>>;

export const setup = async (
  walletClient: WalletClient
): Promise<{
  network: Awaited<ReturnType<typeof setupNetwork>>;
  systemCalls: ReturnType<typeof createSystemCalls>;
}> => {
  console.debug("Setting up MUD client invoked...");
  const network = await setupNetwork(walletClient);
  const systemCalls = createSystemCalls({ ...network, walletClient });

  return {
    network,
    systemCalls,
  };
};
