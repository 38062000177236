import memoize from "memoizee";

import {
  Coords,
  CoordsWithMeta,
  CoordsWithMetaAndRelations,
  GateTypes,
} from "./types";
import allSystems from "../../assets/formatted.json";
import npcStationLocations from "../../assets/npcStations.json";
import riftDiscoveriesLocations from "../../assets/riftDiscoveries.json";
import systemNamesToIds from "../../assets/systemNamesById.json";
import {
  distanceBetweenTwoPoints,
  getNumberCoordsAsBigInt,
  lightYearsBetweenTwo3DPoints,
} from "@/utils";
import { IMapState, MapActions, MapDisplayType } from "./reducer";
import * as THREE from "three";
import { COLOR_THEME, mapDisplayTypeKey, SETTINGS } from "./constants";
import IndexerHTTPService from "@/services/IndexerHTTPService";
import { GatewayHTTPService } from "@/services";
import {
  NodeId,
  SmartObjectId,
  SmartObjectType,
  SolarSystemId,
  NodeObject,
} from "@/types";

export const calculateLinksDistances = (
  state: IMapState
): { source: number; target: number; distance: number; linkId: number }[] => {
  if (!state.graph) return [];
  // console.log("~~linksrerender~~", state.graph.graphData().links.map((node) => ({...node, source: { ...node.source as NodeObject, __threeObj: undefined }, target: { ...node.target as NodeObject, __threeObj: undefined }, __lineObj: undefined})))
  const z = state.graph!.graphData().links.map((link) => {
    if (!link.source || !link.target) return undefined;
    // const sourceNode = state.nodes[parseInt((link.source as NodeObject).id.toString())];
    // const targetNode = state.nodes[parseInt((link.target as NodeObject).id.toString())];

    // const distance = Math.sqrt(
    //   Math.pow(sourceNode.x - targetNode.x, 2) +
    //   Math.pow(sourceNode.y - targetNode.y, 2) +
    //   Math.pow(sourceNode.z - targetNode.z, 2)
    // );

    return {
      source: parseInt((link.source as NodeObject).id.toString()),
      target: parseInt((link.target as NodeObject).id.toString()),
      distance: 1,
      linkId: link.index,
    };
  });
  return z.filter((i) => Boolean(i)) as {
    source: number;
    target: number;
    distance: number;
    linkId: number;
  }[];
};

export const buildAdjacencyList = (
  links: {
    source: string | number;
    target: string | number;
    distance: number;
    linkId: number;
  }[]
) => {
  const adjacencyList: Record<
    number,
    { target: number; distance: number; linkId: number }[]
  > = {};
  // console.log(
  //   "~~links~~",
  //   links.map((node) => ({
  //     ...node,
  //     __threeObj: undefined,
  //     __lineObj: undefined,
  //   }))
  // );
  links.forEach(({ source, target, distance, linkId }) => {
    const sourceKey = parseInt(source.toString());
    const targetKey = parseInt(target.toString());
    if (!adjacencyList[sourceKey]) adjacencyList[sourceKey] = [];
    // if (!adjacencyList[targetKey]) adjacencyList[targetKey] = [];

    adjacencyList[sourceKey].push({ target: targetKey, distance, linkId });
    // adjacencyList[targetKey].push({ target: sourceKey, distance, linkId }); // For undirected graph
  });
  // console.log(`~~~~~ adjacencyList ~~~~~ `, adjacencyList);
  return adjacencyList;
};

// export const dikjstra = (state: IMapState, startNodeId: number, endNodeId: number) => {
//   if (!state.graph) return { path: [], distance: null };

//   const distances: Record<NodeId, number> = {}; // Initialize distances as Infinity
//   const previousNodes: Record<NodeId, number | null> = {};
//   const nodes = state.graph.graphData().nodes;

//   const nodeIds = nodes.filter(({ id }) => id).map((node) => parseInt(node.id!.toString()));
//   const unvisited = new Set(nodeIds);

//   // Initialize all distances to Infinity, except for the start node
//   nodeIds.forEach(id => distances[id as NodeId] = Infinity);
//   distances[startNodeId as NodeId] = 0;

//   const adjacencyList = buildAdjacencyList(calculateLinksDistances(state));
//   console.log('Adjacency List:', adjacencyList);

//   // Main loop
//   let loop = 0
//   while (unvisited.size > 0) {
//     loop += 1;
//     // Get the node with the smallest distance
//     const currentNode = Array.from(unvisited).reduce((closestNode, node) =>
//       (distances[node as NodeId] || Infinity) < (distances[closestNode as NodeId] || Infinity) ? node : closestNode
//     );
//     // if (loop % 1000 === 0) {
//     // console.log(`Visiting node ${currentNode}:`, distances);
//     // }
//     if (currentNode === endNodeId) break; // Shortest path found
//     unvisited.delete(currentNode);

//     // Update distances to neighbors
//     if (!adjacencyList[currentNode]) {
//       continue;
//     }

//     adjacencyList[currentNode].forEach(({ target, distance }) => {
//       const newDistance = (distances[currentNode as NodeId] || Infinity) + distance;
//       if (newDistance < (distances[target as NodeId] || Infinity)) {
//         distances[target as NodeId] = newDistance;
//         previousNodes[target as NodeId] = currentNode;
//       }
//     });
//   }

//   // Backtrack to get the path
//   const path = [];
//   let current: number | null = endNodeId;
//   while (current !== null) {
//     path.unshift(current);
//     console.log(`Backtracking path: ${current}`, previousNodes);
//     current = previousNodes[current as NodeId] || null;
//   }

//   // If the end node's distance is still Infinity, it means there's no path
//   if (distances[endNodeId as NodeId] === Infinity) {
//     return { path: [], distance: null };
//   }

//   return { path, distance: distances[endNodeId as NodeId] };
// };

// export const dfs = (adjacencyList: Record<number, {target:number, distance: number}[] >, startNodeId: number, endNodeId: number, path: number[] = [], visited = new Set()): number[] | null => {
//   visited.add(startNodeId);
//   path.push(startNodeId);

//   if (startNodeId === endNodeId) {
//     return path; // Found the path
//   }

//   for (let { target } of adjacencyList[startNodeId] || []) {
//     if (!visited.has(target)) {
//       const result = dfs(adjacencyList, target, endNodeId, [...path], visited);
//       if (result) {
//         return result; // Return the path if found
//       }
//     }
//   }

//   return null; // No path found
// }
// export const dfsRoot = (state: IMapState, startNodeId: number, endNodeId: number) => {
//   const adjacencyList = buildAdjacencyList(calculateLinksDistances(state));
//   return dfs(adjacencyList, startNodeId, endNodeId) as number[];
// }

const getOnlinedSmartAssembliesInSystems = async (): Promise<
  Record<SolarSystemId, BigInt[]>
> => {
  const indexerInstance = IndexerHTTPService.getInstance();
  const smartAssembliesInSystems =
    await indexerInstance.getSmartAssemblyLocations();
  return Object.entries(smartAssembliesInSystems).reduce<
    Record<SolarSystemId, BigInt[]>
  >((acc, [smartObjectId, smartAssemblyLocation]) => {
    if (!smartAssemblyLocation) return acc;
    const solarSystem = smartAssemblyLocation.solarSystemId;
    if (!acc[solarSystem]) {
      acc[solarSystem] = [];
    }
    acc[solarSystem].push(BigInt(smartObjectId.toString()));
    return acc;
  }, {});
};

const getSmartObjectTypes = async (): Promise<
  Record<SmartObjectId, SmartObjectType>
> => {
  const indexerInstance = IndexerHTTPService.getInstance();
  return indexerInstance.getSmartObjectTypes();
};

/**
 * Get a map of solar system ids to smart gate ids by querying the indexer
 * for onlined smart assemblies in systems as well as smart assembly types and filtering the smart gates
 *
 * @returns a map of solar system ids to smart gate ids
 */
export const getOnlinedSmartGatesInSystems = async (): Promise<
  Record<SolarSystemId, BigInt[]>
> => {
  const [smartAssembliesInSystems, smartObjectTypes] = await Promise.all([
    getOnlinedSmartAssembliesInSystems(),
    getSmartObjectTypes(),
  ]);
  const smartGates = Object.entries(smartAssembliesInSystems).reduce<
    Record<SolarSystemId, BigInt[]>
  >((acc, [solarSystemId, smartObjectIds]) => {
    smartObjectIds.forEach((smartObjectId) => {
      const smartObjectIdString = smartObjectId.toString() as SmartObjectId;
      if (smartObjectTypes[smartObjectIdString] === SmartObjectType.SmartGate) {
        if (!acc[solarSystemId as SolarSystemId]) {
          acc[solarSystemId as SolarSystemId] = [];
        }
        acc[solarSystemId as SolarSystemId].push(smartObjectId);
      }
    });
    return acc;
  }, {});
  return smartGates;
};

const memoizedGetScaledFactor = memoize(
  () => {
    const coords: CoordsWithMetaAndRelations[] =
      allSystems as CoordsWithMetaAndRelations[];
    const locationCoordsToUse = coords.reduce<CoordsWithMetaAndRelations[]>(
      (acc, item, i) => {
        if (
          !(
            item.solarSystemId &&
            item.a_name &&
            item.locationX &&
            item.locationY &&
            item.locationZ
          )
        ) {
          return acc;
        }
        const { locationX, locationY, locationZ, fx, fy, fz, ...rest } = item;
        acc.push({
          ...rest,
          locationX,
          locationY,
          locationZ,
          x: locationX,
          y: locationY,
          z: locationZ,
        });
        return acc;
      },
      []
    );
    return getScaleValue(locationCoordsToUse);
  },
  { maxAge: 60 * 1000 }
);
export const getScaleFactor = () => {
  return memoizedGetScaledFactor();
};

const memoizedGetCoords = memoize(
  async (
    dispatch: any,
    smartCharacterId: string,
    getCanJumpStatus: (
      characterId: bigint,
      gates: {
        solarSystemId: SolarSystemId;
        source: bigint;
        destination: bigint;
      }[]
    ) => Promise<
      Record<
        string,
        {
          source: bigint;
          destination: bigint;
          jumpApproved: boolean;
          solarSystemId: SolarSystemId;
        }[]
      >
    >
  ) => {
    console.time("getCoords");

    const coords: CoordsWithMetaAndRelations[] =
      allSystems as CoordsWithMetaAndRelations[];
    const locationCoordsToUse = coords.reduce<CoordsWithMetaAndRelations[]>(
      (acc, item, i) => {
        if (
          !(
            item.solarSystemId &&
            item.a_name &&
            item.locationX &&
            item.locationY &&
            item.locationZ
          )
        ) {
          return acc;
        }
        const { locationX, locationY, locationZ, fx, fy, fz, ...rest } = item;
        acc.push({
          ...rest,
          locationX,
          locationY,
          locationZ,
          x: locationX,
          y: locationY,
          z: locationZ,
        });
        return acc;
      },
      []
    );
    const gatewayInstance = GatewayHTTPService.getInstance();
    const indexerInstance = IndexerHTTPService.getInstance();
    const filteredSolarSystemIds = locationCoordsToUse
      .filter((node) => !!node.solarSystemId)
      .map((node) => node.solarSystemId?.toString()); // solarSystemId
    const [smartAssembliesMap, smartGatesMap, smartGatesLinksMap] =
      await Promise.all([
        getOnlinedSmartAssembliesInSystems().then((d) => {
          dispatch({
            type: MapActions.SET_ACTIVE_ASSEMBLIES_COUNT_MAP,
            payload: d,
          });
          return d;
        }),
        getOnlinedSmartGatesInSystems().then((d) => {
          dispatch({
            type: MapActions.SET_ACTIVE_SMART_GATES_COUNT_MAP,
            payload: d,
          });
          return d;
        }),
        indexerInstance.getSmartGateLinks().then(async (d) => {
          console.log("HERE:", d);
          const arr = Object.entries(d).map(
            ([solarSystemId, { source, destination }]) => {
              if (!source || !destination || !solarSystemId) return;
              return {
                solarSystemId: solarSystemId as SolarSystemId,
                source: source as bigint,
                destination: destination as bigint,
              };
            }
          );
          // console.log("getting can jump status", smartCharacterId, arr);
          const results = await getCanJumpStatus(
            BigInt(smartCharacterId),
            arr.filter((obj) => obj !== undefined)
          );
          // console.log("~~results~~", results);
          Object.entries(d).forEach(
            ([solarSystemId, { source, destination }]) => {
              if (!source || !destination) return;
              const gates =
                results[`${source.toString()}-${destination.toString()}`];
              if (gates.length > 0) console.log("gates", gates);
              if (gates.every((gate) => !gate.jumpApproved)) {
                // console.log(
                //   "deleting",
                //   solarSystemId,
                //   " cant jump: ",
                //   source,
                //   destination
                // );
                delete d[solarSystemId];
              }
            }
          );
          dispatch({
            type: MapActions.SET_SMART_GATES_MAP,
            payload: d,
          });
          return d;
        }),
      ]).finally(() => {
        dispatch({
          type: MapActions.SET_ASYNC_FILTERS_IS_ENABLED,
          payload: null,
        });
      });

    const linksMap = new Map<
      string,
      { source: number; target: number; type: GateTypes }
    >();

    //   const locationCoordsScaled = scaleEachTo100(locationCoordsToUse);
    // filter out locations that don't have all the required fields
    // const middlePosition = centerGraph(locationCoordsToUse);
    // const shiftedCoords = shiftCoordinatesToCenter(
    //   locationCoordsToUse,
    //   middlePosition
    // );
    // console.log(`~~~~~ locationCoordsToUse ~~~~~ `, locationCoordsToUse.length);
    const scaledCoords = scaleCoordinatesToRange(locationCoordsToUse);
    // setInitialFocusedCoords(extractMiddleOfCoords(scaledCoords));
    const nodes = scaledCoords.map((location, idx) => {
      return {
        a_name: location.a_name,
        id: idx,
        solarSystemId: location.solarSystemId,
        constellationID: location.constellationID,
        regionID: location.regionID,
        name: location.a_name,
        relations: location.relations,
        relationsLength: location.relations.length,
        x: location.x,
        y: location.y,
        z: location.z,
        locationX: location.locationX,
        locationY: location.locationY,
        locationZ: location.locationZ,
      };
    });
    // console.log(`~~~~~ nodes ~~~~~ `, nodes.length);

    const mapOfNodes = nodes.reduce<Record<string, any>>(
      (acc, curr) => {
        acc.byId[curr.solarSystemId.toString()] = curr;
        acc.byName[curr.name.toLowerCase()] = curr;
        if (!acc.constellationMap[curr.constellationID]) {
          acc.constellationMap[curr.constellationID] = [];
        }
        acc.constellationMap[curr.constellationID].push(curr.id);
        if (!acc.regionMap[curr.regionID]) {
          acc.regionMap[curr.regionID] = [];
        }
        acc.regionMap[curr.regionID].push(curr.id);
        return acc;
      },
      { byId: {}, byName: {}, constellationMap: {}, regionMap: {} }
    );
    // add the smart gate links to the links map
    const smartObjectMap = Object.entries(smartGatesMap).reduce<
      Record<string, SolarSystemId>
    >((acc, [solarSystemId, smartGateIds]) => {
      if (!smartGateIds) return acc;
      smartGateIds.forEach((smartGateId) => {
        acc[smartGateId.toString()] = solarSystemId as SolarSystemId;
      });
      return acc;
    }, {});
    console.log("smartGatesLinksMap", smartGatesLinksMap);
    Object.entries(smartGatesLinksMap).forEach(
      ([
        sourceId,
        { source: sourceSmartObjectId, destination: destinationSmartObjectId },
      ]) => {
        const sourceSolarSystemId =
          smartObjectMap[sourceSmartObjectId.toString()];
        const destinationSolarSystemId =
          smartObjectMap[destinationSmartObjectId.toString()];
        if (!sourceSolarSystemId || !destinationSolarSystemId) return;
        const linkId = `${sourceSolarSystemId.toString()}-${destinationSolarSystemId.toString()}`;
        if (
          linksMap.has(linkId) ||
          sourceSolarSystemId === destinationSolarSystemId
        ) {
          return;
        }
        const sourceNode = mapOfNodes.byId[sourceSolarSystemId.toString()];

        const targetNode = mapOfNodes.byId[destinationSolarSystemId.toString()];
        if (!sourceNode || !targetNode) return;
        linksMap.set(linkId, {
          source: sourceNode.id,
          target: targetNode.id,
          type: GateTypes.SMART_GATE,
          // name: `${node.name} - ${targetNode.name}: ${rel.distance.toString().slice(0, 6)}LY`,
        });
        return;
      }
    );

    console.log(`~~~~~ linksmapsize with smart gates ~~~~~ `, linksMap);
    nodes.forEach((node) => {
      node.relations.forEach((rel) => {
        const targetNode = mapOfNodes.byId[rel.solarSystemId.toString()];
        if (!targetNode) {
          return;
        }
        const linkId = `${node.solarSystemId.toString()}-${targetNode.solarSystemId.toString()}`;
        if (
          linksMap.has(linkId) ||
          node.solarSystemId === targetNode.solarSystemId
        ) {
          return;
        }
        if (!rel.distance) {
          console.error("No distance found for relation", rel);
          return;
        }
        linksMap.set(linkId, {
          source: node.id,
          target: targetNode.id,
          type: GateTypes.NPC_GATE,
          // name: `${node.name} - ${targetNode.name}: ${rel.distance.toString().slice(0, 6)}LY`,
        });
        return;
      });
    });
    const data = {
      nodes,
      links: Array.from(linksMap.values()),
      type: "NPC Gate",
      constellationMap: mapOfNodes.constellationMap,
      regionMap: mapOfNodes.regionMap,
      nodesMap: mapOfNodes.byName,
      nodesMapById: mapOfNodes.byId,
    };
    console.timeEnd("getCoords");
    // console.log(`~~~~~ data ~~~~~ `, data.nodes.length);

    return data;
  },
  { maxAge: 60 * 1000 }
);

export const getCoords = async (
  dispatch: any,
  smartCharacterId: string,
  getCanJumpStatus: any
): Promise<{
  nodes: CoordsWithMetaAndRelations[];
  links: any[];
  type: string;
  nodesMap: { [key: string]: CoordsWithMetaAndRelations };
  nodesMapById: { [key: string]: CoordsWithMetaAndRelations };
  constellationMap: { [key: string]: number[] };
  regionMap: { [key: string]: number[] };
}> => {
  return await memoizedGetCoords(dispatch, smartCharacterId, getCanJumpStatus);
};

function centerNodes<T>(nodes: any[]): T {
  const center = { x: 0, y: 0, z: 0 };

  nodes.forEach((node) => {
    center.x += node.x;
    center.y += node.y;
    center.z += node.z;
  });

  center.x /= nodes.length;
  center.y /= nodes.length;
  center.z /= nodes.length;

  nodes.forEach((node) => {
    node.x -= center.x;
    node.y -= center.y;
    node.z -= center.z;
  });

  return nodes as T;
}

const centerGraph = (nodes: CoordsWithMeta[]) => {
  const centroid = nodes.reduce(
    (acc, node) => {
      acc.x += node.x;
      acc.y += node.y;
      acc.z += node.z;
      return acc;
    },
    { x: 0, y: 0, z: 0 }
  );
  centroid.x /= nodes.length;
  centroid.y /= nodes.length;
  centroid.z /= nodes.length;

  nodes.forEach((node) => {
    node.x -= centroid.x;
    node.y -= centroid.y;
    node.z -= centroid.z;
  });

  return centroid; // Optional, if you want to log or use it elsewhere
};
export const getScaleValue = (
  coords: CoordsWithMetaAndRelations[],
  scaleTo: number = 1000
) => {
  const maxX = Math.max(
    ...coords.map((c, idx) => (idx > 100 ? Math.abs(c.x) : 0))
  );
  const maxY = Math.max(
    ...coords.map((c, idx) => (idx > 100 ? Math.abs(c.y) : 0))
  );
  const maxZ = Math.max(
    ...coords.map((c, idx) => (idx > 100 ? Math.abs(c.z) : 0))
  );
  // const medX = Math.avg(...coords.map((c) => Math.abs(c.x)));
  const max = Math.max(maxX, maxY, maxZ);
  return scaleTo / max;
};
export const scaleCoordinatesToRange = (
  coords: CoordsWithMetaAndRelations[],
  scaleTo: number = 1000
): CoordsWithMetaAndRelations[] => {
  const scaleFactor = getScaleValue(coords, scaleTo);

  const scaledCoords = coords.map((c) => {
    const { locationX, locationY, locationZ, fx, fy, fz, ...rest } = c;
    return {
      ...rest,
      locationX,
      locationY,
      locationZ,
      x: c.x * scaleFactor,
      y: c.y * scaleFactor,
      z: c.z * scaleFactor,
    };
  });
  return scaledCoords;
};

export const noOp = () => {};

export const shiftCoordinatesToCenter = (
  coords: CoordsWithMetaAndRelations[],
  middle: { x: number; y: number; z: number }
): CoordsWithMetaAndRelations[] => {
  return coords.map((c) => ({
    ...c,
    x: c.x - middle.x,
    y: c.y - middle.y,
    z: c.z - middle.z,
  }));
};

export const extractMiddleOfCoords = (coords: CoordsWithMeta[]): Coords => {
  const allX = coords.reduce((acc, curr) => acc + curr.x, 0);
  const allY = coords.reduce((acc, curr) => acc + curr.y, 0);
  const allZ = coords.reduce((acc, curr) => acc + curr.z, 0);

  const len = coords.length;

  return {
    x: allX / len,
    y: allY / len,
    z: allZ / len,
  };
};

export const extractMapOfClosestSystems = (
  origin: CoordsWithMeta,
  coords: CoordsWithMeta[],
  numberOfEntities = 100
) => {
  // Buffer to store the closest systems
  const slots: { system: CoordsWithMeta; distance: number }[] = [];

  let maxDistance = -Infinity; // Track the maximum distance in the slots array

  for (const system of coords) {
    const distance = distanceBetweenTwoPoints(system, origin);

    if (slots.length < numberOfEntities) {
      // Add system to the buffer if there's room
      slots.push({ system, distance });
      maxDistance = Math.max(maxDistance, distance); // Update max distance in the buffer
    } else if (distance < maxDistance) {
      // Replace the farthest system in the buffer
      let farthestIndex = -1;

      // Find the farthest system
      for (let i = 0; i < slots.length; i++) {
        if (slots[i].distance === maxDistance) {
          farthestIndex = i;
          break;
        }
      }

      // Replace the farthest system
      if (farthestIndex !== -1) {
        slots[farthestIndex] = { system, distance };
      }

      // Recalculate max distance
      maxDistance = Math.max(...slots.map((s) => s.distance));
    }
  }

  // Convert to the required output format
  return slots.reduce<Record<string, boolean>>((acc, entry) => {
    acc[entry.system.id] = true;
    return acc;
  }, {});
};

export const deriveStartPosition = (coords: CoordsWithMeta[]): Coords => {
  const middle = extractMiddleOfCoords(coords);
  return { x: middle.x - 500, y: middle.y + 300, z: middle.z + 750 };
};

const manipulateColor = (
  colorRange: { start: string; end: string },
  colorOne: string,
  colorTwo: string,
  colorThree: string
) => {
  // align colors to range
  const start = parseInt(colorRange.start, 16);
  const end = parseInt(colorRange.end, 16);
  const colorOneInt = parseInt(colorOne, 16);
  const colorTwoInt = parseInt(colorTwo, 16);
  const colorThreeInt = parseInt(colorThree, 16);
  const manipulatedColorOne = Math.round(
    ((colorOneInt - start) / (end - start)) * 255
  );
  const manipulatedColorTwo = Math.round(
    ((colorTwoInt - start) / (end - start)) * 255
  );
  const manipulatedColorThree = Math.round(
    ((colorThreeInt - start) / (end - start)) * 255
  );
  const hexValue = `#${manipulatedColorOne.toString(16)}${manipulatedColorTwo.toString(16)}${manipulatedColorThree.toString(16)}`;
  return hexValue;
};

export const deriveColorFromConstellationId = (
  num: number,
  avoidHex = "#000000",
  minDistance = 200
) => {
  // Helper: Convert Hex to RGB
  const hexToRgb = (hex: string) => {
    const bigint = parseInt(hex.slice(1), 16);
    return [(bigint >> 16) & 255, (bigint >> 8) & 255, bigint & 255];
  };

  // Helper: Calculate Distance Between Colors
  const colorDistance = (rgb1: any, rgb2: any) => {
    return Math.sqrt(
      Math.pow(rgb1[0] - rgb2[0], 2) +
        Math.pow(rgb1[1] - rgb2[1], 2) +
        Math.pow(rgb1[2] - rgb2[2], 2)
    );
  };

  // Hash function for deterministic results
  const seed = (num * 2654435761) % Math.pow(2, 32); // Knuth's hash
  let hue = seed % 360; // Map to hue (0-359)
  const saturation = 50 + (seed % 20); // Saturation: 50-70%
  const lightness = 60 + (seed % 10); // Lightness: 60-70%

  const avoidRgb = hexToRgb(avoidHex); // Convert avoidHex to RGB

  // Generate a color and check its distance
  let [r, g, b] = hslToRgb(hue, saturation, lightness);
  while (colorDistance([r, g, b], avoidRgb) < minDistance) {
    // Adjust hue slightly if too close to avoid color
    hue = (hue + 30) % 360;
    [r, g, b] = hslToRgb(hue, saturation, lightness);
  }

  // Convert RGB to Hex
  return `#${((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1)}`;
};

// Convert HSL to RGB
const hslToRgb = (h: number, s: number, l: number) => {
  s /= 100;
  l /= 100;
  const k = (n: number) => (n + h / 30) % 12;
  const a = s * Math.min(l, 1 - l);
  const f = (n: number) =>
    l - a * Math.max(-1, Math.min(k(n) - 3, Math.min(9 - k(n), 1)));
  return [
    Math.round(255 * f(0)),
    Math.round(255 * f(8)),
    Math.round(255 * f(4)),
  ];
};
export const deriveColorFromConstellationIdOld = (num: number) => {
  // Use a hash function to ensure a deterministic mapping
  const seed = (num * 2654435762) % Math.pow(2, 32); // Knuth's hash
  const hue = seed % 360; // Map to hue (0-359)
  const saturation = 50 + (seed % 20); // Saturation between 70-100%
  const lightness = 50 + (seed % 10); // Lightness between 70-80%

  const [r, g, b] = hslToRgb(hue, saturation, lightness);

  // Convert RGB to Hex
  return `#${((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1)}`;
};

export const deriveCameraPosition = (
  startNode?: CoordsWithMeta,
  destinationNode?: CoordsWithMeta
) => {
  // Account for the flipped Y-axis (needs to be negative)
  const adjustedY = 100;
  if (startNode && destinationNode) {
    // get midpoint between the two nodes
    const midpoint = extractMiddleOfCoords([startNode, destinationNode]);
    // get the distance between the two nodes
    const distance = distanceBetweenTwoPoints(startNode, destinationNode);
    // determine the distance between the midpoint and the nodes for the purpose of zooming out
    const distRatio =
      1 + distance / Math.hypot(midpoint.x, midpoint.y, midpoint.z);
    // figure out the zoom distance based on the distance between the midpoint and the nodes to set the camera position

    return {
      x: midpoint.x * distRatio,
      y: adjustedY,
      z: midpoint.z * distRatio,
    };
  } else if (startNode ?? destinationNode) {
    const focusNode = startNode ?? (destinationNode as CoordsWithMeta);
    const distance = 5;
    const distRatio =
      1 + distance / Math.hypot(focusNode.x, focusNode.y, focusNode.z);
    return {
      x: focusNode.x * distRatio,
      y: adjustedY,
      z: focusNode.z * distRatio,
    };
  }
  return { x: 0, z: 0, y: 250 };
};

export const deriveFocusPosition = (
  startNode?: CoordsWithMeta,
  destinationNode?: CoordsWithMeta
) => {
  if (startNode ?? destinationNode) {
    const focusNode = startNode ?? (destinationNode as CoordsWithMeta);
    // Account for the flipped axis (needs to be negative)
    return {
      x: focusNode.x * SETTINGS.scale[0],
      y: focusNode.y * SETTINGS.scale[1],
      z: focusNode.z * SETTINGS.scale[2],
    };
  }
  return { x: 0, z: 0, y: 0 };
};

const deriveColorsOfNode =
  (state: IMapState, map: Record<string, MapItem>, colors: Float32Array) =>
  (node: any, i: number) => {
    const color =
      map[node[mapDisplayTypeKey[state.mapDisplayType]]]?.color ||
      defaultSystemColor;
    colors[i * 3] = color.r;
    colors[i * 3 + 1] = color.g;
    colors[i * 3 + 2] = color.b;
    return;
  };

const deriveRootColors =
  (state: IMapState, colors: Float32Array) => (link: any, i: number) => {
    const color =
      link.type === GateTypes.SMART_GATE
        ? primaryHighlighterColor
        : defaultLinkColor;
    colors[i * 3] = color.r;
    colors[i * 3 + 1] = color.g;
    colors[i * 3 + 2] = color.b;
  };

export const buildLinkInstanceMap = (state: IMapState) =>
  state.path.reduce<Record<string, LinkItem>>((acc, cur) => {
    acc[cur.linkId.toString()] = { color: pathColor, brightness: 1 };
    return acc;
  }, {});

export const deriveColorsOfLink =
  (state: IMapState, map: Record<string, LinkItem>, colors: Float32Array) =>
  (link: any, i: number) => {
    if (map[i.toString()]) {
      const colorToUse =
        link.type === GateTypes.SMART_GATE
          ? smartGateHighlighterColor
          : map[i.toString()].color;
      colors[i * 3] = colorToUse.r;
      colors[i * 3 + 1] = colorToUse.g;
      colors[i * 3 + 2] = colorToUse.b;
      return;
    }
    deriveRootColors(state, colors)(link, i);
    return;
  };

const deriveOverlaysOfLink =
  (state: IMapState, map: Record<string, LinkItem>, overlays: Float32Array) =>
  (link: any, i: number) => {
    if (map[i.toString()]) {
      overlays[i] = map[i.toString()].brightness;
      return;
    }
    overlays[i] = 1;
    return;
  };

export const deriveColorsAndOverlayOfLink =
  (
    state: IMapState,
    map: Record<string, LinkItem>,
    colors: Float32Array,
    overlays: Float32Array
  ) =>
  (link: any, i: number) => {
    deriveColorsOfLink(state, map, colors)(link, i);
    deriveOverlaysOfLink(state, map, overlays)(link, i);
  };

const deriveRadiiOfNode =
  (state: IMapState, map: Record<string, MapItem>, radii: Float32Array) =>
  (node: any, i: number) => {
    radii[i] =
      map[node[mapDisplayTypeKey[state.mapDisplayType]]]?.radius ||
      defaultSystemRadius;
    return;
  };

export const deriveColorsAndRadii =
  (
    state: IMapState,
    map: Record<string, MapItem>,
    colors: Float32Array,
    radii: Float32Array
  ) =>
  (node: any, i: number) => {
    deriveColorsOfNode(state, map, colors)(node, i);
    deriveRadiiOfNode(state, map, radii)(node, i);
    return;
  };

const pathColor = new THREE.Color(COLOR_THEME.pathColor);

const defaultLinkColor = new THREE.Color(COLOR_THEME.linkColor);
const defaultSystemColor = new THREE.Color(COLOR_THEME.baseSystemColor);
const tertiaryStationColor = new THREE.Color(COLOR_THEME.tertiaryStationColor);
const defaultSystemRadius = SETTINGS.nodeRadius;
const primaryHighlighterColor = new THREE.Color(COLOR_THEME.npcStationColor);
const smartGateHighlighterColor = new THREE.Color(
  COLOR_THEME.smartGateHighlighterColor
);
const secondaryHighlighterColor = new THREE.Color(
  COLOR_THEME.selectedDestinationSystemColor
);

export interface MapItem {
  color: THREE.Color;
  radius: number;
}

export interface LinkItem {
  color: THREE.Color;
  brightness: number;
}

const deriveColorFromDate = (_: Date) => {
  return new THREE.Color(COLOR_THEME.npcStationColor);
};

export const riftDiscoveriesMap = (() => {
  const systemIdsByName = Object.entries(systemNamesToIds).reduce<
    Record<string, string>
  >((acc, [id, name]) => {
    acc[name] = id;
    return acc;
  }, {});
  return Object.entries(riftDiscoveriesLocations).reduce<
    Record<string, MapItem>
  >(
    (acc, [systemName, date]) => {
      const systemId: string = systemIdsByName[systemName];
      if (!systemId) return acc;
      // get date from date fed in in mm/dd/yyyy format
      const dateToUse: Date = new Date(date as string);
      acc[systemId] = {
        color: deriveColorFromDate(dateToUse),
        radius: 4,
      };
      return acc;
    },
    {} as Record<string, MapItem>
  );
})();

const deriveNpcStationColor = (typeId: string) => {
  switch (typeId) {
    case "85226":
      return secondaryHighlighterColor;
    case "85227":
      return primaryHighlighterColor;
    case "78943":
    default:
      return tertiaryStationColor;
  }
};

export const npcStationsColorsMap = (() =>
  (allSystems as any[]).reduce(
    (acc, props) => {
      if (
        npcStationLocations[
          props.solarSystemId as keyof typeof npcStationLocations
        ]
      ) {
        const color = deriveNpcStationColor(
          npcStationLocations[
            props.solarSystemId as keyof typeof npcStationLocations
          ]
        );
        acc[props.solarSystemId] = {
          color: color,
          radius: SETTINGS.highlightedNodeRadius,
        };
      } else {
        acc[props.solarSystemId] = {
          color: new THREE.Color(COLOR_THEME.baseSystemColor),
          radius: SETTINGS.nodeRadius,
        };
      }
      return acc;
    },
    {} as Record<string, MapItem>
  ))();

const deriveRadiusMapValuesBetweenTwoRangesOnRange = (
  map: Record<string, number>,
  range = [3, 15]
) => {
  // determine the range based on the upper and lower bound of the map values
  const values = Object.values(map);
  const min = Math.min(...values);
  const max = Math.max(...values);
  const rangeMin = range[0];
  const rangeMax = range[1];
  // then create the value based on where it lies between value min and max
  const fn = (value: number) => {
    if (value === 0) {
      return 0;
    }
    return rangeMin + ((value - min) / (max - min)) * (rangeMax - rangeMin);
  };
  return Object.keys(map).reduce<Record<string, number>>((acc, key) => {
    acc[key] = fn(map[key]);
    return acc;
  }, {});
};
export const buildInstanceMap = (
  mapDisplayType: MapDisplayType,
  regionMap: any,
  activeAssembliesCountMap: Record<string, BigInt[]>,
  smartGatesMap: Record<string, BigInt[]>
): Record<string, MapItem> => {
  switch (mapDisplayType) {
    case MapDisplayType.NONE:
      return {};
    case MapDisplayType.NPC_STATION:
      return npcStationsColorsMap;
    case MapDisplayType.RIFTS:
      return riftDiscoveriesMap;
    case MapDisplayType.SMART_GATES_COUNT: {
      const radiusMap = deriveRadiusMapValuesBetweenTwoRangesOnRange(
        Object.entries(smartGatesMap).reduce<Record<string, number>>(
          (acc, [id, gateIds]) => {
            acc[id] = gateIds.length;
            // acc[id] = 3;
            return acc;
          },
          {}
        )
      );
      return Object.keys(activeAssembliesCountMap).reduce<
        Record<string, MapItem>
      >((acc, id, idx) => {
        acc[id] = {
          color:
            !radiusMap[id] || radiusMap[id] === 0
              ? new THREE.Color(COLOR_THEME.baseSystemColor)
              : primaryHighlighterColor,
          radius:
            !radiusMap[id] || radiusMap[id] === 0
              ? SETTINGS.nodeRadius
              : radiusMap[id],
        };
        return acc;
      }, {});
    }

    case MapDisplayType.ACTIVE_ASSEMBLIES_COUNT: {
      const radiusMap = deriveRadiusMapValuesBetweenTwoRangesOnRange(
        Object.entries(activeAssembliesCountMap).reduce<Record<string, number>>(
          (acc, [id, assemblyIds]) => {
            acc[id] = assemblyIds.length;
            return acc;
          },
          {}
        )
      );
      return Object.keys(activeAssembliesCountMap).reduce<
        Record<string, MapItem>
      >((acc, id, idx) => {
        acc[id] = {
          color:
            !radiusMap[id] || radiusMap[id] === 0
              ? new THREE.Color(COLOR_THEME.baseSystemColor)
              : primaryHighlighterColor,
          radius:
            !radiusMap[id] || radiusMap[id] === 0
              ? SETTINGS.nodeRadius
              : radiusMap[id],
        };
        return acc;
      }, {});
    }
    case MapDisplayType.REGION:
    default:
      return Object.keys(regionMap).reduce<Record<string, MapItem>>(
        (acc, id) => {
          acc[id] = {
            color: new THREE.Color(
              deriveColorFromConstellationId(
                parseInt(id),
                `0x${COLOR_THEME.selectedSystemColor.toString(16)}`
              )
            ),
            radius: SETTINGS.nodeRadius,
          };
          return acc;
        },
        {}
      );
  }
};
